import { template as template_830556b195e44fefaa4935b16ce81b37 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_830556b195e44fefaa4935b16ce81b37(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
