import { template as template_b56aae9470754abb9d7d231f77b36975 } from "@ember/template-compiler";
const FKLabel = template_b56aae9470754abb9d7d231f77b36975(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
