import { template as template_b0e7f5d98cb24541984c9e8995f17bbc } from "@ember/template-compiler";
const SidebarSectionMessage = template_b0e7f5d98cb24541984c9e8995f17bbc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
