import { template as template_91b32afc75a146aa8181063efc8a25eb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_91b32afc75a146aa8181063efc8a25eb(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
