import { template as template_75e3bb5c0bc4470fb4decc4ddb8f45cb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_75e3bb5c0bc4470fb4decc4ddb8f45cb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
