import { template as template_12a5c3a0e54d4855823e0eb36801255b } from "@ember/template-compiler";
const WelcomeHeader = template_12a5c3a0e54d4855823e0eb36801255b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
